import s from './AboutScc.module.css';
import {Aside} from "../../aside/Aside";
import img from "./imgs/img.png"
import {MoreDetailed} from "../../moreDetailed/MoreDetailed";
import {ReactComponent as Logo} from "./imgs/grl.svg";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const AboutScc = () => {
    const {t} = useTranslation()

    return(
        <div className={s.Wrapper}>
            <div className={s.Container}>
                <Aside value= {t("SCCAbout")}/>
                <div className={s.ContentWrapper}>
                    <img src={img} alt="img" className={s.Image}/>
                    <div className={s.Abra}>
                        <div className={s.WrapperDesc}>
                            <p className={s.Description}>
                                {t("SCCDescription")}
                            </p>
                            <Link to={"/Arbitration_an_appointment"}>
                                <MoreDetailed/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}