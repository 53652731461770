import s from "./News2.module.css";
import {Aside} from "../aside/Aside";
import img1 from "./newsImage.png";
import img2 from '../mainPage/mainNews/imgs/img2.png';
import img3 from '../mainPage/mainNews/imgs/img3.png';
import img4 from '../mainPage/mainNews/imgs/img4.png';
import img5 from '../mainPage/mainNews/imgs/img5.png';
import img6 from '../mainPage/mainNews/imgs/img6.png';
import img7 from '../mainPage/mainNews/imgs/img8.png';

import {MoreDetailed} from "../moreDetailed/MoreDetailed";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import X2JS from "x2js";
import axios from "axios";


export type RootObject = RootObjectChild[];
export type RootObjectChildGuid = {
	_isPermaLink: string;
	__text: string;
}
export type RootObjectChildCreator = {
	__prefix: string;
	__cdata: string;
}
export type RootObjectChildContent = {
	_medium: string;
	_url: string;
	__prefix: string;
}
export type RootObjectChild = {
	title: string;
	description: string;
	link: string;
	guid: RootObjectChildGuid;
	creator: RootObjectChildCreator;
	pubDate: string;
	content: RootObjectChildContent;
	enclosure: any;
}

export default function News2 () {
    const [data, setData] = useState<RootObject | null>(null);
    const {t, i18n} = useTranslation()

    useEffect(() => {
        let lang_urls:any = {
            'ru': 'https://cyprusworld.online/main/news?lang=ru',
            'en': 'https://cyprusworld.online/main/news?lang=en',
            'gr': 'https://cyprusworld.online/main/news?lang=en'
        }
        axios
            .get(lang_urls[i18n.language], {
                headers: {
                    "Content-Type": "application/xml; charset=utf-8"
                }
            })
            .then(function (response) {
                // const parser = new xml2js.Parser();
                // parser.parseString(response.data, function(err, result) {
                //     setData(result)
                // })
                const x2js = new X2JS();
                const result: any = x2js.xml2js(response.data);
                setData(result.rss.channel.item)
            })
            .catch(function (error) {
                // console.log(error);
            });
    },[i18n.language] )

    return (
        <div className={s.Wrapper}>
            <div className={s.Container}>
                <Aside value={t("Footer.NewsAndEvents.list.li1")}/>
                <div className={s.MainBlock}>
                    <div className={s.ContentWrapper}>
                        {data?.map(i => {
                            const dateN = new Date(i.pubDate).toLocaleString('ru-Ru', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                            });
                            return(
                                <div className={s.New}>
                                    <div>
                                        <a href={i.link} target="_blank"><img src={i.enclosure?._url || img1} alt="img1" className={s.Image}/></a>
                                        <p className={s.Date}>{dateN}</p>
                                        <a href={i.link} target="_blank">{i.title}</a>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
