import React from "react";
import {ReactComponent as Logo} from "./LogoType.svg";
import s from "./Footer.module.css";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {Link as ScrollLink} from "react-scroll";
import {ReactComponent as Que} from "../../hint/Que.svg";
import {ReactComponent as Telephone} from "../main/imgs/tel.svg";
import {ReactComponent as Mail} from "./email.svg";

export const Footer = () => {
    const nav = useNavigate()
    const {t} = useTranslation()
    return (
        <div className={s.Wrapper}>
            <div className={s.Container}>
                <div className={s.Column1}>
                    <Link to={'/'} className={s.WrapperLogo}>
                        <Logo className={s.Logo}/>
                        <p>Cyprus Arbitrage<br/>
                            EC&CI
                        </p>
                    </Link>
                    {/*<p>Нейтральное, независимое<br/> и беспристрастное место<br/> встречи для разрешения<br/> споров по всему миру.</p>*/}
                    <p>{t("Footer.logoDescription")}</p>
                </div>
                <div className={s.Column2}>
                    <h3><Link to={'/Our_services'}>{t("Footer.services.heading")}</Link></h3>
                    <ul className={s.List}>
                        <li><Link to={'/Arbitration_an_appointment'}> {t("Footer.services.list.li1")}</Link></li>
                        <li><Link to={"/EC&CI_Express"}>{t("Footer.services.list.li2")}</Link></li>
                        {/*<li>*/}
                        {/*    */}
                        {/*    <ScrollLink*/}
                        {/*        to="counter"*/}
                        {/*        spy={true}*/}
                        {/*        smooth={true}*/}
                        {/*        offset={1}*/}
                        {/*        duration={1200}*/}
                        {/*        className={s.BtnWrapper}*/}
                        {/*    >*/}
                        {/*        {t("Footer.services.list.li3")}*/}
                        {/*    </ScrollLink>*/}
                        {/*</li>*/}
                        <li><Link to={"/Arbitration_rules"}> {t("Footer.services.list.li4")}</Link>
                        </li>
                        {/*<li>{t("Footer.services.list.li5")}</li>*/}
                    </ul>
                </div>
                <div className={s.Column3}>
                    <h3>{t("Footer.aboutSCC.heading")}</h3>
                    <ul className={s.List}>
                        <li><Link to={"EC&CI_Arbitration"}>{t("Footer.aboutSCC.list.li1")}</Link></li>
                        <li>
                            <Link to={"Contact_us"}>{t("Footer.aboutSCC.list.li2")}</Link>

                        </li>
                        {/*<li>{t("Footer.aboutSCC.list.li3")}</li>*/}
                        {/*<li>{t("Footer.aboutSCC.list.li4")}</li>*/}
                        {/*<li>{t("Footer.aboutSCC.list.li5")}</li>*/}
                    </ul>
                </div>
                <div className={s.Column4}>
                    <h3><Link to={"News"}>
                        {t("Footer.NewsAndEvents.list.li1")}
                    </Link></h3>
                    <div className={s.Contact}>
                        <span><Telephone className={s.Tel}/>+35777788242</span>
                        <p>{t("freeLine")}</p>
                        <span className={s.TR}><Mail className={s.Mail}/>support@cyprusworld.online</span>
                    </div>

                </div>
            </div>

        </div>
    )
}