import React from "react";
import s from "./Events.module.css";
import {ReactComponent as Calendar} from "./imgs/Calendar.svg";
import {ReactComponent as Time} from "./imgs/Time.svg";
import {ReactComponent as Location} from "./imgs/Location.svg";
import img from "./imgs/img.png";
import img2 from "./imgs/img2.png";
import img3 from "./imgs/img3.png";
import LLL from "./imgs/img.jpg";
import {ReactComponent as Union} from "../../news/imgs/Union.svg";
import {Aside} from "../../aside/Aside";
import {BtnArrow} from "../../btnArrow/BtnArrow";
import {MoreDetailed} from "../../moreDetailed/MoreDetailed";
import {Link} from "react-router-dom";
import {t} from "i18next";
import {useTranslation} from "react-i18next";

export const Events = () => {
    const {t} = useTranslation()
    return (
        <div className={s.Wrapper}>
            <div className={s.Container}>
                <Aside value={t("CalendarOfEvents.aside")}/>
                <div>
                    <div className={s.Column1}>
                        <img src={img} alt="img"/>
                        <div className={s.Description}>
                            <div>
                                <h4>{t("CalendarOfEvents.theConference")}</h4>
                                <h2>{t("CalendarOfEvents.h2OfConference")}</h2>
                            </div>
                            <div className={s.Date}>
                                <Calendar/>
                                21.25.2020
                            </div>
                            <div className={s.Time}>
                                <Time/>
                                17:40
                            </div>
                            <div className={s.Location}>
                                <Location/>
                                {t("CalendarOfEvents.locationOfConference")}
                            </div>
                            <p>{t("CalendarOfEvents.descriptionOfConference")}
                            </p>
                            <div className={s.More}>
                                <Link to={'/eventPage'} className={s.More}>
                                    <MoreDetailed/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={s.Column2}>
                        <img src={img2} alt="img"/>
                        <div className={s.Description}>
                            <div>
                                <h4>Конференция</h4>
                                <h2>Энергетический переход и климатические споры - почему сейчас?</h2>
                            </div>
                            <div className={s.Date}>
                                <Calendar/>
                                21.25.2020
                            </div>
                            <div className={s.Time}>
                                <Time/>
                                17:40
                            </div>
                            <div className={s.Location}>
                                <Location/>
                                Стокгольм, Regeringsgatan 29
                            </div>
                            <p>Арбитражный институт EC&CI, Чартерный институт арбитров и
                                CEDR приглашают вас принять участие в
                                Форуме по переходу на энергоносители 18 апреля 2023 года.</p>
                            <div className={s.More}>
                                <Link to={'/eventPage'} className={s.More}>
                                    <MoreDetailed/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={s.Column1}>
                        <img src={LLL} alt="img"/>
                        <div className={s.Description}>
                            <div>
                                <h4>Конференция</h4>
                                <h2>Энергетический переход и климатические споры - почему сейчас?</h2>
                            </div>
                            <div className={s.Date}>
                                <Calendar/>
                                21.25.2020
                            </div>
                            <div className={s.Time}>
                                <Time/>
                                17:40
                            </div>
                            <div className={s.Location}>
                                <Location/>
                                Стокгольм, Regeringsgatan 29
                            </div>
                            <p>Арбитражный институт EC&CI, Чартерный институт арбитров и
                                CEDR приглашают вас принять участие в
                                Форуме по переходу на энергоносители 18 апреля 2023 года.
                            </p>
                            <div className={s.More}>
                                <Link to={'/eventPage'} className={s.More}>
                                    <MoreDetailed/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Link to={'/events'} className={s.SubscribeWrapper}>
                        <BtnArrow value={'Все события'}/>
                    </Link>
                    {/*<div className={s.SubscribeWrapper}>*/}
                    {/*  */}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}