import s from "./MainSlider.module.css";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {ReactComponent as Union} from "../imgs/VectorLeft.svg";
import {t} from "i18next";
import {Link} from "react-router-dom";

const NextArrow = (props:any) => {
    return(
        <div onClick={props.onClick} className={s.Arrow1}>
           <Union/>
        </div>
    )
}
const PrevArrow = (props:any) => {
    return(
        <div onClick={props.onClick} className={s.Arrow2}>
           <Union/>
        </div>
    )
}
type MainSliderType = {
    t:any
}

export const MainSlider = () => {

    const settings = {
        // fade: true,
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1200,
        autoplaySpeed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: s.MyMainCustomDots,
        activeDotsClass:s.ActiveDots,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    const slide = [
        {p:t("services.title1"), h3:t("services.description1"), Link:'/EC&CI_Arbitration'},
        {p:t("services.title2"), h3:t("services.description2"), Link:'/EC&CI_Expedited_Arbitration'},
        {p:t("services.title3"), h3:t("services.description3"), Link:'/EC&CI_Express'},
        {p:t("services.title4"), h3:t("services.description4"), Link:'/EC&CI_Emergency_Arbitrator'},
        {p:t("services.title5"), h3:t("services.description5"), Link:'/EC&CI_Mediation'},
    ]

    return (
       <div className={s.Abra}>
           <Slider {...settings} className={s.CustomSlider}>
               {
                   slide.map(sl => {
                       return(
                           <div className={s.Statistics} >
                               <p>{sl.p}</p>
                               <h3>{sl.h3}</h3>
                               <Link to={sl.Link} className={s.Link}>{t("services.Readmore")}</Link>
                           </div>
                       )
                   })
               }
           </Slider>
       </div>
        )
}