import React, {useEffect, useRef, useState} from "react";
import s from "./Production.module.css";
import {Aside} from "../../aside/Aside";
import {BtnArrow} from "../../btnArrow/BtnArrow";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FetchUserDataTC, FetchUserDataWithDebounceTC} from "../../../state/UserDataReducer";
import {AppRootStateType} from "../../../state/store";
import {useTranslation} from "react-i18next";

export const Production = () => {
    const nav = useNavigate()
    const [value, setValue] = useState("");
    const dispatch: Function = useDispatch()
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(FetchUserDataWithDebounceTC(value))
    }, [value]);
    const isLoading = useSelector<AppRootStateType, boolean>(state => state.userData.isLoading);
    const error = useSelector<AppRootStateType, string>(state => state.userData.error);


    return (
        <div className={s.Wrapper}>
            <div className={s.Container}>
                <Aside value={t("Production.title")}/>
                <div>
                    <form action="" className={s.CaseWrapper}>
                        <p>{t("Production.input_title")}</p>
                        {/*<label>*/}
                        {/*    УКАЖИТЕ НОМЕР ДЕЛА*/}
                        <input onChange={e => setValue(e.currentTarget.value)} value={value} type={'text'} required
                               className={s.InputCase} placeholder={'AA223344-112-2'}/>
                        {value && !isLoading && <span className={s.Error}>{error}</span>}
                        {/*</label>*/}
                        {/*<input ref={ref} type={'text'} required className={s.InputCase} placeholder={'AA223344-112-2'}/>*/}
                        <div className={s.WrapperBtn}>
                            <BtnArrow disabled={isLoading || !!error} value={t("Production.find_btn")} onClick={() => {
                                nav('/case/' + encodeURIComponent(value))
                            }}/>
                            {/*<button>найти</button>*/}
                        </div>
                    </form>
                </div>
                <div className={s.Description}>
                    <p>
                        {t("Production.description")}
                    </p>
                    <p>{t("Production.description2")}</p>
                </div>
            </div>
        </div>
    )
}