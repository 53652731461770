import s from "./Support.module.css";
import {Aside} from "../../aside/Aside";
import {Field, Form, Formik} from "formik";
import {ReactComponent as Union} from "../mainMailing/Union.svg";
import {ReactComponent as Vector} from "../mainMailing/Vector.svg";
import React from "react";
import {useTranslation} from "react-i18next";

const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));

export const Support = () => {
    const {t} = useTranslation()
    return(
        <div className={s.Wrapper} id={"Support"} >
            <div className={s.Container}>
                <Aside value={t("Support.Aside")}/>
                <div className={s.FormBlock}>
                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                            telephone: '',
                        }}
                        onSubmit={async (values, {setStatus}) => {
                            await sleep(500);
                            // alert(JSON.stringify(values, null, 2));
                            setStatus(values)
                        }}
                    >
                        {({isSubmitting, status}) => (
                            !status ?
                                <Form className={s.Form}>
                                    <div className={s.FormContainer}>
                                        <div className={s.Column1}>
                                            <label htmlFor="email"  className={s.Label}>{t("Support.email")}</label>
                                            <Field name="email" required type={'email'}
                                                   placeholder={t("Support.email")} className={s.Field}/>
                                        </div>
                                        <div className={s.Column3}>
                                            <label htmlFor="number" className={s.Label}>{t("Support.tel")}</label>
                                            <Field name="number" type={'number'} placeholder={t("Support.placeTel")} className={s.Field}/>
                                        </div>
                                        <div className={s.Column2}>
                                            <label htmlFor="lastName"  className={s.Label}>{t("Support.fio")}</label>
                                            <Field name="lastName" type={'text'} placeholder={t("Support.fio")}  className={s.Field}/>
                                        </div>
                                    </div>
                                    <div className={s.CommentBlock}>
                                        <label htmlFor="comment"  className={s.Label}>{t("Support.comment")}</label>
                                        <Field name="comment" required placeholder={t("Support.interComment")}  className={s.Field}/>
                                    </div>
                                    <div className={s.BtnWrapper}>
                                        <button type="submit" disabled={isSubmitting} className={s.Btn}>
                                            {t("Support.subscribe")}
                                        </button>
                                        <Union/>
                                    </div>
                                </Form>
                                : <div className={s.Success}>
                                    <Vector/>
                                    <p> {t("Support.success")}</p>
                                </div>)}
                    </Formik>
                </div>
            </div>
        </div>
    )
}