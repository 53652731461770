import s from "./Menu2.module.css";
import React, {MutableRefObject, ReactNode, useEffect, useRef, useState} from "react";
import styles from "./Styles2.module.css";
import {ReactComponent as Vector} from "./Vector.svg";
import {Accordion, AccordionItem as Item} from "@szhsin/react-accordion";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

// @ts-ignore
const AccordionItem = ({header, ...rest}) => {
        const [isActive, setIsActive] = useState(false);
        const toggleAccordion = () => {
            setIsActive(!isActive)
        }

        return (

            <Item
                {...rest}
                header={
                    // <Link to={'courts'} className={s.Link}>
                    <div onClick={toggleAccordion} className={styles.WrapperHeaderAccordion}>
                        <div className={styles.Header}>
                            {header}</div>
                        <Vector className={styles.chevron}/>
                    </div>
                    // </Link>

                }
                className={styles.item}
                buttonProps={{
                    className: ({isEnter}) =>
                        `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`
                }}
                contentProps={{className: styles.itemContent}}
                panelProps={{className: styles.itemPanel}}
            />)
    }
;
type Accordion2type = {
    header: string
    active: boolean
    setActiveMenu: (tr: boolean) => void
    children: ReactNode
}

const AccordionItem2 = ({header, active, setActiveMenu, ...rest}: Accordion2type) => {
    const [isActive, setIsActive] = useState(true);
    const toggleAccordion = () => {
        setIsActive(!isActive)
    }

    return (
        <Item
            {...rest}
            header={
                <div className={styles.WrapperHeaderAccordion}
                >
                    {/*<Link to={"Our_services"} className={styles.Header}*/}
                    {/*      // onClick={}*/}
                    {/*>*/}
                        {header}
                    {/*</Link>*/}
                    <Vector className={styles.chevron} onClick={toggleAccordion}/>
                </div>
            }
            className={styles.item}
            buttonProps={{
                className: ({isEnter}) =>
                    `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`
            }}
            contentProps={{className: styles.itemContent}}
            panelProps={{className: styles.itemPanel}}
        />)
}

type Menu2Type = {
    setActiveMenu: (tr: boolean) => void
    active: boolean
}
export const Menu2 = (props: Menu2Type) => {
    const {t} = useTranslation()
    const containerRef = useRef<HTMLDivElement>(null)

    const handleContextMenu = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation()

    }
    return (
        <div ref={containerRef} className={`${s.Wrapper} ${props.active ? s.ActiveWrapper : ''}`}
             onClick={() => props.setActiveMenu(false)}
        >
            {/*<div className={s.Blur}></div>*/}
            <div className={s.Container} onClick={handleContextMenu}>
                <p className={s.Heading}>{t("Menu")}</p>
                <Accordion transition allowMultiple transitionTimeout={500} className={styles.Szh}>
                    <AccordionItem2 header={t("Nav.OurServices")}
                                    setActiveMenu={props.setActiveMenu}
                                    active={props.active}>
                        <ul className={s.List}>
                            <li className={s.ListItem} onClick={() => props.setActiveMenu(!props.active)}>
                                <Link to={'EC&CI_Arbitration'} className={s.Link}>{t("services.title1")}</Link>
                                {/*<Vector className={styles.chevron}/>*/}
                            </li>
                            <li className={s.ListItem} onClick={() => props.setActiveMenu(!props.active)}>
                                <Link to={'EC&CI_Expedited_Arbitration'}
                                      className={s.Link}>{t("services.title2")}</Link>
                                {/*<Vector className={styles.chevron}/>*/}
                            </li>
                            <li className={s.ListItem} onClick={() => props.setActiveMenu(!props.active)}>
                                <Link to={'EC&CI_Express'} className={s.Link}>{t("services.title3")}</Link>
                                {/*<Vector className={styles.chevron}/>*/}
                            </li>
                            <li className={s.ListItem} onClick={() => props.setActiveMenu(!props.active)}>
                                <Link to={'EC&CI_Emergency_Arbitrator'} className={s.Link}>{t("services.title4")}</Link>
                                {/*<Vector className={styles.chevron}/>*/}
                            </li>
                            <li className={s.ListItem} onClick={() => props.setActiveMenu(!props.active)}>
                                <Link to={'EC&CI_Mediation'} className={s.Link}>{t("services.title5")}</Link>
                                {/*<Vector className={styles.chevron}/>*/}
                            </li>
                            <li className={s.ListItem} onClick={() => props.setActiveMenu(!props.active)}>
                                <Link to={'EC&CI_Investment_Disputes'} className={s.Link}>{t("services.title6")}</Link>
                                {/*<Vector className={styles.chevron}/>*/}
                            </li>
                            <li className={s.ListItem} onClick={() => props.setActiveMenu(!props.active)}>
                                <Link to={'SEC&CI_Ad_Hoc_Services'} className={s.Link}>{t("services.title7")}</Link>
                                {/*<Vector className={styles.chevron}/>*/}
                            </li>
                            <li className={s.ListItem} onClick={() => props.setActiveMenu(!props.active)}>
                                <Link to={'EC&CI_UNCITRAL'} className={s.Link}>{t("services.title8")}</Link>
                                {/*<Vector className={styles.chevron}/>*/}
                            </li>
                        </ul>
                    </AccordionItem2>
                </Accordion>
                <Accordion transition allowMultiple transitionTimeout={500} className={styles.Szh}>
                    <AccordionItem header={t("SCCAbout")}>
                        <ul className={s.List}>
                            <li className={s.ListItem} onClick={() => props.setActiveMenu(!props.active)}>
                                <Link to={'Arbitration_an_appointment'}
                                      className={s.Link}>{t("ArbitrationAppointment.heading")}</Link>
                                {/*<Vector className={styles.chevron}/>*/}
                            </li>
                            <li className={s.ListItem} onClick={() => props.setActiveMenu(!props.active)}>
                                {/*<Link to={'Services1'} className={s.Link}>{t("services.title1")}</Link>*/}
                                <Link to={'EC&CI_Arbitration'} className={s.Link}>{t("Service1.Heading")}</Link>
                            </li>
                        </ul>
                    </AccordionItem>
                </Accordion>
                <ul className={s.List}>
                    <li className={s.ListItemReal} onClick={() => props.setActiveMenu(!props.active)}>
                        <Link to={'news'} className={s.LinkReal}>{t("Footer.NewsAndEvents.list.li1")}</Link>
                    </li>
                    <li className={s.ListItemReal} onClick={() => props.setActiveMenu(!props.active)}>
                        <Link to={'Arbitration_rules'} className={s.LinkReal}>
                            {t("ModelClause")}
                        </Link>
                    </li>
                    <li className={s.ListItemReal} onClick={() => props.setActiveMenu(!props.active)}>
                        <Link to={'Guide_to_arbitration'} className={s.LinkReal}>  {t("StepByStepGuide.link")}</Link>
                    </li>
                    <li className={s.ListItemReal} onClick={() => props.setActiveMenu(!props.active)}>
                        <Link to={'Contact_us'} className={s.LinkReal}>
                            {t("Contacts")}
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}