import React, {ChangeEvent, useState} from "react";
import s from "./Counter.module.css";
import {Aside} from "../../aside/Aside";
import {BtnArrow} from "../../btnArrow/BtnArrow";
import {ReactComponent as Vector} from "./imgs/Vector.svg";
import {ShortCase} from "../shortcase/ShortCase";
import {useTranslation} from "react-i18next";


export const Counter = () => {
    const {t} = useTranslation();

    const [currency, setCurrency] = useState<'EUR'>('EUR')
    const [value, setValue] = useState<number>(0)
    const [price, setPrice] = useState<number | null>(0)
    const [countOfArbitrators, setCountOfArbitrators] = useState<any>(1)
    const [isDone, setIsDone] = useState<boolean>(false);
    const [AdministrativeFee, setAdministrativeFee] = useState(0);
    const [OverheadCosts, setOverheadCosts] = useState(0);
    const [TaxValue, setTaxValue] = useState(0);
    const [error, setError] = useState<any>('');

    const toggleCurrencies = () => {
        setIsDone(!isDone)
    }

    const changeValue = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.valueAsNumber)
        setError('')
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCountOfArbitrators(e.currentTarget.value);
    };

    return (
        <div className={s.Wrapper} id={"counter"}>
            <div className={s.Container}>
                <Aside value={t("Counter.Aside")}/>
                {
                    !price ?
                        <div className={s.Content}>
                            <div className={s.WrapperCounter}>
                                <div className={s.List}>
                                    <p>{t("Counter.ValueOfDispute")}</p>
                                    <div className={s.CountBlock}>
                                        <input type="number" className={s.InputCurrency} value={value}
                                               onChange={changeValue}/>
                                        <div className={s.CurrencyWrapper} onClick={toggleCurrencies}>
                                            <p onClick={() => setCurrency('EUR')} className={s.Bl}>{currency}</p>
                                        </div>
                                    </div>
                                    {<span className={s.Error}>{error}</span>}
                                </div>
                                <div className={s.Rules}>
                                    <p>{t("Counter.Rules")}</p>
                                    <div>
                                        <input className={s.CustomRadio} type="radio" id="color-1" name="color"
                                            // checked={true}
                                               defaultChecked={true}
                                               value="indigo"/>
                                        <label htmlFor="color-1">{t("Counter.ArbitrationRules")}</label>
                                    </div>
                                    <div>
                                        <input className={s.CustomRadio} type="radio" id="color-2" name="color"
                                               value="iriska"/>
                                        <label htmlFor="color-2">{t("Counter.Soon")}</label>
                                    </div>
                                    <p className={s.Conditions}>{t("Counter.postScript")}</p>
                                </div>
                                <div className={s.ChooseArbitrators}>
                                    <p>{t("Counter.CountOfArbitrators")}</p>
                                    <div>
                                        <input className={s.CustomRadio} type="radio" id="arbitration-1"
                                               name="arbitrator"
                                               value={1} onChange={onChange}/>
                                        <label htmlFor="arbitration-1">1</label>
                                    </div>
                                    <div>
                                        <input className={s.CustomRadio} type="radio" id="arbitration-2"
                                               name="arbitrator"
                                               defaultChecked={true}
                                               value={3} onChange={onChange}/>
                                        <label htmlFor="arbitration-2">3</label>
                                    </div>
                                </div>
                                <div className={s.WrapperBtn}>
                                    <BtnArrow onClick={() => {
                                        if (value < 1) {
                                            return setError(t("error.message"))
                                        }
                                        if (value < 1000) {
                                            setPrice(217)
                                            setAdministrativeFee((217 * 40) / 100)
                                            setTaxValue((217 * 10) / 100)
                                            return setOverheadCosts((217 * 50) / 100)
                                        }
                                        if (value < 2500) {
                                            setPrice(249)
                                            setAdministrativeFee((249 * 40) / 100)
                                            setTaxValue((249 * 10) / 100)
                                            return setOverheadCosts((249 * 50) / 100)
                                        }
                                        if (value < 3500) {
                                            setPrice(289)
                                            setAdministrativeFee((289 * 40) / 100)
                                            setTaxValue((289 * 10) / 100)
                                            return setOverheadCosts((289 * 50) / 100)
                                        }
                                        if (value < 4800) {
                                            setPrice(312)
                                            setAdministrativeFee((312 * 40) / 100)
                                            setTaxValue((312 * 10) / 100)
                                            return setOverheadCosts((312 * 50) / 100)
                                        }
                                        if (value < 5000) {
                                            setPrice(321)
                                            setAdministrativeFee((321 * 40) / 100)
                                            setTaxValue((321 * 10) / 100)
                                            return setOverheadCosts((321 * 50) / 100)
                                        }
                                        if (value < 7000) {
                                            setPrice(365)
                                            setAdministrativeFee((365 * 40) / 100)
                                            setTaxValue((365 * 10) / 100)
                                            return setOverheadCosts((365 * 50) / 100)
                                        }
                                        if (value < 10000) {
                                            setPrice(436)
                                            setAdministrativeFee((436 * 40) / 100)
                                            setTaxValue((436 * 10) / 100)
                                            return setOverheadCosts((436 * 50) / 100)
                                        }
                                        if (value < 13000) {
                                            setPrice(512)
                                            setAdministrativeFee((512 * 40) / 100)
                                            setTaxValue((512 * 10) / 100)
                                            return setOverheadCosts((512 * 50) / 100)
                                        }
                                        if (value < 16000) {
                                            setPrice(587)
                                            setAdministrativeFee((587 * 40) / 100)
                                            setTaxValue((587 * 10) / 100)
                                            return setOverheadCosts((587 * 50) / 100)
                                        }
                                        if (value < 18000) {
                                            setPrice(631)
                                            setAdministrativeFee((631 * 40) / 100)
                                            setTaxValue((631 * 10) / 100)
                                            return setOverheadCosts((631 * 50) / 100)
                                        }
                                        if (value < 21000) {
                                            setPrice(711)
                                            setAdministrativeFee((711 * 40) / 100)
                                            setTaxValue((711 * 10) / 100)
                                            return setOverheadCosts((711 * 50) / 100)
                                        }
                                        if (value < 24000) {
                                            setPrice(797)
                                            setAdministrativeFee((797 * 40) / 100)
                                            setTaxValue((797 * 10) / 100)
                                            return setOverheadCosts((797 * 50) / 100)
                                        }
                                        if (value < 28000) {
                                            setPrice(832)
                                            setAdministrativeFee((832 * 40) / 100)
                                            setTaxValue((832 * 10) / 100)
                                            return setOverheadCosts((832 * 50) / 100)
                                        }
                                        if (value < 32000) {
                                            setPrice(889)
                                            setAdministrativeFee((889 * 40) / 100)
                                            setTaxValue((889 * 10) / 100)
                                            return setOverheadCosts((889 * 50) / 100)
                                        }
                                        if (value < 39000) {
                                            setPrice(921)
                                            setAdministrativeFee((921 * 40) / 100)
                                            setTaxValue((921 * 10) / 100)
                                            return setOverheadCosts((921 * 50) / 100)
                                        }
                                        if (value < 45000) {
                                            setPrice(990)
                                            setAdministrativeFee((990 * 40) / 100)
                                            setTaxValue((990 * 10) / 100)
                                            return setOverheadCosts((990 * 50) / 100)
                                        }
                                        if (value < 48000) {
                                            setPrice(1157)
                                            setAdministrativeFee((1157 * 40) / 100)
                                            setTaxValue((1157 * 10) / 100)
                                            return setOverheadCosts((1157 * 50) / 100)
                                        }
                                        if (value < 55000) {
                                            setPrice(1362)
                                            setAdministrativeFee((1362 * 40) / 100)
                                            setTaxValue((1362 * 10) / 100)
                                            return setOverheadCosts((1362 * 50) / 100)
                                        }
                                        if (value < 62000) {
                                            setPrice(1678)
                                            setAdministrativeFee((1678 * 40) / 100)
                                            setTaxValue((1678 * 10) / 100)
                                            return setOverheadCosts((1678 * 50) / 100)
                                        }
                                        if (value < 67000) {
                                            setPrice(1899)
                                            setAdministrativeFee((1899 * 40) / 100)
                                            setTaxValue((1899 * 10) / 100)
                                            return setOverheadCosts((1899 * 50) / 100)
                                        }
                                        if (value < 68000) {
                                            setPrice(1956)
                                            setAdministrativeFee((1956 * 40) / 100)
                                            setTaxValue((1956 * 10) / 100)
                                            return setOverheadCosts((1956 * 50) / 100)
                                        }
                                        if (value < 71000) {
                                            setPrice(2045)
                                            setAdministrativeFee((2045 * 40) / 100)
                                            setTaxValue((2045 * 10) / 100)
                                            return setOverheadCosts((2045 * 50) / 100)
                                        }
                                        if (value < 74000) {
                                            setPrice(2255)
                                            setAdministrativeFee((2255 * 40) / 100)
                                            setTaxValue((2255 * 10) / 100)
                                            return setOverheadCosts((2255 * 50) / 100)
                                        }
                                        if (value < 78000) {
                                            setPrice(2456)
                                            setAdministrativeFee((2456 * 40) / 100)
                                            setTaxValue((2456 * 10) / 100)
                                            return setOverheadCosts((2456 * 50) / 100)
                                        }
                                        if (value < 81000) {
                                            setPrice(2678)
                                            setAdministrativeFee((2678 * 40) / 100)
                                            setTaxValue((2678 * 10) / 100)
                                            return setOverheadCosts((2678 * 50) / 100)
                                        }
                                        if (value < 84000) {
                                            setPrice(2989)
                                            setAdministrativeFee((2989 * 40) / 100)
                                            setTaxValue((2989 * 10) / 100)
                                            return setOverheadCosts((2989 * 50) / 100)
                                        }
                                        if (value < 88000) {
                                            setPrice(3121)
                                            setAdministrativeFee((3121 * 40) / 100)
                                            setTaxValue((3121 * 10) / 100)
                                            return setOverheadCosts((3121 * 50) / 100)
                                        }
                                        if (value < 81000) {
                                            setPrice(2678)
                                            setAdministrativeFee((2678 * 40) / 100)
                                            setTaxValue((2678 * 10) / 100)
                                            return setOverheadCosts((2678 * 50) / 100)
                                        }
                                        if (value < 95000) {
                                            setPrice(3344)
                                            setAdministrativeFee((3344 * 40) / 100)
                                            setTaxValue((3344 * 10) / 100)
                                            return setOverheadCosts((3344 * 50) / 100)
                                        }
                                        if (value < 10300) {
                                            setPrice(3654)
                                            setAdministrativeFee((3654 * 40) / 100)
                                            setTaxValue((3654 * 10) / 100)
                                            return setOverheadCosts((3654 * 50) / 100)
                                        }
                                        if (value < 110000) {
                                            setPrice(3788)
                                            setAdministrativeFee((3788 * 40) / 100)
                                            setTaxValue((3788 * 10) / 100)
                                            return setOverheadCosts((3788 * 50) / 100)
                                        }
                                        if (value < 125000) {
                                            setPrice(3997)
                                            setAdministrativeFee((3997 * 40) / 100)
                                            setTaxValue((3997 * 10) / 100)
                                            return setOverheadCosts((3997 * 50) / 100)
                                        }
                                        if (value < 137000) {
                                            setPrice(4340)
                                            setAdministrativeFee((4340 * 40) / 100)
                                            setTaxValue((4340 * 10) / 100)
                                            return setOverheadCosts((4340 * 50) / 100)
                                        }
                                        if (value < 145000) {
                                            setPrice(4799)
                                            setAdministrativeFee((4799 * 40) / 100)
                                            setTaxValue((4799 * 10) / 100)
                                            return setOverheadCosts((4799 * 50) / 100)
                                        }
                                        if (value < 155000) {
                                            setPrice(5655)
                                            setAdministrativeFee((5655 * 40) / 100)
                                            setTaxValue((5655 * 10) / 100)
                                            return setOverheadCosts((5655 * 50) / 100)
                                        }
                                        if (value >= 155000) {
                                            setPrice(6800)
                                            setAdministrativeFee((6800 * 40) / 100)
                                            setTaxValue((6800 * 10) / 100)
                                            return setOverheadCosts((6800 * 50) / 100)
                                        }
                                    }} value={t("Counter.Estimate")}/>
                                </div>

                            </div>
                            <div className={s.Description}>
                                <p>{t("Counter.SetPrice")}
                                </p>
                                <p>
                                    {t("Counter.descPrice")}
                                </p>
                            </div>
                        </div>
                        : <ShortCase value={value} currency={currency}
                                     price={price}
                                     setPrice={setPrice}
                                     countOfArbitrators={countOfArbitrators}
                                     OverheadCosts={OverheadCosts}
                                     AdministrativeFee={AdministrativeFee}
                                     TaxValue={TaxValue}/>}
            </div>
        </div>
    )
}