import React from "react";
import s from './Services.module.css';
import {Aside} from "../../aside/Aside";
import {BtnArrow} from "../../btnArrow/BtnArrow";
import {MoreDetailed} from "../../moreDetailed/MoreDetailed";
import {ReactComponent as Group1} from "./imgs/Group.svg";
import {ReactComponent as Group2} from "./imgs/Group2.svg";
import {ReactComponent as Time} from "./imgs/Time.svg";
import {ReactComponent as Person} from "./imgs/Person.svg";
import {ReactComponent as Message} from "./imgs/Message.svg";
import {ReactComponent as Usd} from "./imgs/usd.svg";
import {ReactComponent as Law} from "./imgs/Law.svg";
import {ReactComponent as Company} from "./imgs/Company.svg";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

type ServicesType = {
    logo:any
    title:string
    desc:string
    Link:string
}

export const Services = () => {
    const {t} = useTranslation()

    const state:ServicesType[] = [
        {logo:<Group1/>,title:t("services.title1"), desc:t("services.description1"), Link:'/EC&CI_Arbitration'},
        {logo:<Group2/>,title:t("services.title2"), desc:t("services.description2"), Link:'/EC&CI_Expedited_Arbitration'},
        {logo:<Time/>,title:t("services.title3"), desc:t("services.description3"), Link:'/EC&CI_Express'},
        {logo:<Person/>,title:t("services.title4"), desc:t("services.description4"), Link:'/EC&CI_Emergency_Arbitrator'},
        {logo:<Message/>,title:t("services.title5"), desc:t("services.description5"), Link:'/EC&CI_Mediation'},
        {logo:<Usd/>,title:t("services.title6"), desc:t("services.description6"), Link:'/EC&CI_Investment_Disputes'},
        {logo:<Law/>,title:t("services.title7"), desc:t("services.description7"), Link:'/SEC&CI_Ad_Hoc_Services'},
        {logo:<Company/>,title:t("services.title8"), desc:t("services.description8"), Link:'/EC&CI_UNCITRAL'},
    ]


    return (
        <div className={s.Wrapper}>
            <div className={s.Container}>
                <Aside value={t("services.Heading")}/>
                <div className={s.ColumnsWrapper}>
                    <div className={s.Column1}>
                        <p className={s.Description}>
                            {t("ServicesDescription")}
                        </p>
                        <div >
                            <Link to={"/Our_services"} className={s.WrapperBtn}>
                                <BtnArrow value={t("SCCMore")}/>
                            </Link>

                        </div>
                    </div>
                    <div className={s.Column2}>
                        {
                            state.map(i => {
                                return(
                                    <div className={s.Item}>
                                        {/*<div>*/}
                                        {i.logo}
                                        <p className={s.Title}>{i.title}</p>
                                        <p className={s.Desc}>{i.desc}</p>
                                        {/*</div>*/}
                                        <Link to={i.Link}>
                                            <span><MoreDetailed/></span>
                                        </Link>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}