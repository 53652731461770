import React from 'react';
import img1 from "../imgs/img1.png";
import s from '../ExpertsAdvice.module.css';
import {MoreDetailed} from "../../../moreDetailed/MoreDetailed";
import {Link} from "react-router-dom";

export type ContentType = {
    h3:string
    p:string
    position:string
    img:any
    Link:string
}

export const Content = (props:ContentType ) => {
    return (
        <div className={s.WrapperHeaderAccordion}>
            <img src={props.img} alt="" className={s.Image}/>
            <span className={s.Count}>{props.position}</span>
            <div>
                <h3>{props.h3} </h3>
                <p>{props.p}</p>
                <Link to={props.Link}>
                    <MoreDetailed/>
                </Link>
            </div>
        </div>
    )
}