import s from "./FullCase2.module.css";
import {Aside} from "../../aside/Aside";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {FetchUserDataTC} from "../../../state/UserDataReducer";
import {AppRootStateType} from "../../../state/store";
import {UserDataType} from "../../../types/types";
import {ReactComponent as Logo} from "../../ourServices/Service1/Vector.svg";


export const FullCase2 = () => {

    const dispatch = useDispatch<any>()
    const {id} = useParams()

    const userdata = useSelector<AppRootStateType, UserDataType>(state => state.userData.userData);
    const isLoading = useSelector<AppRootStateType, boolean>(state => state.userData.isLoading);
    const error = useSelector<AppRootStateType, string>(state => state.userData.error);


    useEffect(() => {
        dispatch(FetchUserDataTC((id)))
    }, []);
    if (isLoading) {
        return <div>Loading...</div>
    }
    if (error) {
        return <div>{error}</div>
    }

    return (
        <div className={s.Wrapper}>
            <div className={s.Container3}>
                <Link to={'/'} className={s.WrapperLogo}>
                    <Logo className={s.Logo}/>
                </Link>
            </div>

            <div className={s.Container}>
                <Aside value={'Производство по материалам '}/>
                <div className={s.Column}>
                    <div className={s.Main}>
                        <h3>Производство по материалам</h3>
                        {/*<p>Материал №3/12-292/2022</p>*/}
                        <div className={s.Table1}>
                            <div className={s.caption}>Материал № {decodeURIComponent(id || "")}</div>
                            <div className={s.NNN}>
                                <div className={s.Deal}>Дело</div>
                            </div>
                            <div className={s.Inside}>
                                <div>ФИО</div>
                                <div>{userdata.user}</div>
                            </div>
                            <div className={s.Inside}>
                                <div>Дата поступления</div>
                                <div>{userdata.date}</div>
                            </div>
                            <div className={s.Inside}>
                                <div>Вид материала</div>
                                <div>{userdata.material_type}</div>
                            </div>
                            <div className={s.Inside}>
                                <div>Предмет предоставления, ходатайства, жалобы</div>
                                <div>{userdata.subject}</div>
                            </div>
                            <div className={s.Inside}>
                                <div>Дата подачи иска</div>
                                <div>{userdata.application_date}</div>
                            </div>
                            <div className={s.Inside}>
                                <div>Дата рассмотрения</div>
                                <div>{userdata.review_date}</div>
                            </div>
                            <div className={s.Inside}>
                                <div>Судья</div>
                                <div>{userdata.judge}</div>
                            </div>
                            <div className={s.Inside}>
                                <div>Результат рассмотрения</div>
                                <div>{userdata.result}</div>
                            </div>

                            </div>
                        </div>

                        {/*Second table*/}

                        <div className={s.Table2}>
                            <div>
                                <div className={s.Deal}>Движение материалов</div>
                            </div>
                            <div className={s.InsideM}>
                                <div></div>
                                <div>Дата</div>
                                <div className={s.Time}>Время</div>
                                <div className={s.Result}>Результат</div>
                                <div>Дата размещения</div>
                            </div>
                            {
                                userdata.events?.map(tl => {
                                    return (
                                        <div className={s.Inside}>
                                            <div>{tl.event_type}</div>
                                            <div className={s.Ar}>
                                                <div className={s.Date}>{tl.date}</div>
                                                <div className={s.Time}>{tl.time}</div>
                                                <div className={s.Result}>{tl.result}</div>
                                                <div>{tl.placement_date}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className={s.Table3}>
                            <div>
                                <div className={s.Deal}>
                                    Стороны
                                </div>
                            </div>
                            <div className={s.Insidde2}>
                                <div></div>
                                <div>ФИО/Наименование</div>
                                <div>Временно исполняющий обязанности</div>
                            </div>
                            {userdata.sides?.map(tl => (
                                <div className={s.Inside2}>
                                    <div>{tl.side_type}</div>
                                    <div className={s.Wr}>
                                        <div>{tl.name}</div>
                                        <div>{tl.temporary_name}</div>
                                    </div>
                                </div>))
                            }


                            {/*<div className={s.Inside2}>*/}
                            {/*    <div>Ответчик</div>*/}
                            {/*    <div className={s.Wr}>*/}
                            {/*        <div>Наименование</div>*/}
                            {/*        <div>Исполняющий обязанности</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className={s.Inside2}>*/}
                            {/*    <div>Адвокат со стороны Истца</div>*/}
                            {/*    <div className={s.Wr}>*/}
                            {/*        <div>Наименование</div>*/}
                            {/*        <div>Исполняющий обязанности</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className={s.Inside2}>*/}
                            {/*    <div>Адвокат со стороны Ответчика</div>*/}
                            {/*   <div className={s.Wr}>*/}
                            {/*       <div>Наименование</div>*/}
                            {/*       <div>Исполняющий обязанности</div>*/}
                            {/*   </div>*/}
                            {/*</div>*/}
                            {/*<div className={s.Inside2}>*/}
                            {/*    <div>Судья</div>*/}
                            {/*    <div className={s.Wr}>*/}
                            {/*        <div>Наименование</div>*/}
                            {/*        <div>Исполняющий обязанности</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
    )
}
