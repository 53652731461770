import {BtnArrow} from "../btnArrow/BtnArrow";
import {Link} from "react-router-dom";
import s from "./NotFound.module.css";
import {ReactComponent as Logo} from "./LogoType.svg";
import React from "react";
import {ReactComponent as Union} from "./Union.svg";
import {useTranslation} from "react-i18next";

export const NotFound = () => {
    const {t} = useTranslation()
    return (
        <div className={s.Wrapper}>
            <Link to={'/'} className={s.WrapperLogo}>
                <Logo className={s.Logo}/>
                <p>Cyprus Arbitrage<br/>
                    EC&CI
                </p>
            </Link>
            <h2>404</h2>
            <h3>{t("error.page")}</h3>
            <Link to={'/'} className={s.WrapperBtn}>
                <button className={s.Btn}>{t("error.ToMAin")}</button>
                <Union className={s.Union}/>
            </Link>

        </div>
    )

}
