import s from "./EventPage.module.css";

import {Aside} from "../aside/Aside";
import img from "./imgs/img.png";
import {ReactComponent as Calendar} from "./imgs/Vector.svg";
import {ReactComponent as Time} from "./imgs/Vector2.svg";
import {ReactComponent as Location} from "./imgs/Vector3.svg";
import {Events} from "../mainPage/mainEvents/Events";
import {Footer} from "../mainPage/footer/Footer";
import {MainMail} from "../mainPage/mainMailing/MainMail";

export const EventPage = () => {
    return (
        <>
            <div className={s.Wrapper}>
                <div className={s.Container}>
                    <Aside value={'События'}/>
                    <div>
                        <div className={s.Bread}>
                            <p>Главная / Новости / Самые большие проблемы в арбитражном сообществе Эстонии - встречайте
                                Пиркку-Марью Пылдвере</p>
                        </div>
                        <h3>Энергетический переход и климатические споры - почему сейчас?</h3>
                        <div className={s.Date}>14.03.2023</div>
                        <div className={s.Cover}>
                            <img src={img} alt=""/>
                            <div className={s.CoverContain}>
                                <div><Calendar className={s.Vector}/>21.25.2020</div>
                                <div><Time className={s.Vector}/>17:40</div>
                                <div><Location className={s.Vector}/>Стокгольм, Regeringsgatan 29</div>
                                <p>Арбитражный институт EC&CI, Чартерный институт арбитров и
                                    CEDR приглашают вас принять участие в Форуме по переходу на
                                    энергоносители 18 апреля 2023 года.</p>
                            </div>
                        </div>
                        <div className={s.Description}>
                            <p>2023 год станет переломным для климатических споров. Мы являемся свидетелями совместного
                                развития международного
                                и внутреннего законодательства о климате с наукой о климате и экономикой перехода к
                                энергетике, что повлияет на
                                всю глобальную экономическую деятельность, гражданское общество и государства. Форум по
                                энергетическому переходу
                                изучит основы споров о переходе на энергоносители, пересечение с ESG и возможные способы
                                смягчения воздействия.
                            </p>
                            <p>Программа начинается со вступительных замечаний Кэролайн Фальконер (Генеральный секретарь
                                в EC&CI) и Вольф фон Кумберг
                                (Посредник и член Арбитра, и Бывший председатель правления, Ciarb), затем следуют
                                ключевые ноты Доктор. Крина Бальтаг (Профессор права в Стокгольмском университете),
                                с ключевыми моментами из обзора Международного энергетического арбитражного отчета
                                королевы Марии. Затем последуют три групповых обсуждения.
                            </p>
                            <p>Споры по переходу на энергоносители будут темой для первой группы, с которой будет
                                обсуждаться Вольф фон Кумберг в качестве модератора.
                                Темы будут представлены Кай Уве-Карл (Глобальный главный судебный советник GE Renewable
                                Energy) Том Мелби Эйде (Независимый руководитель,
                                ранее главный юрисконсульт EVP в Shell) Тасним Азад (Управляющий директор в Kroll)
                                Полина Пермякова (Партнер в Дельфи) и Джессика Кроу (Независимый арбитр в Арбитре).
                            </p>
                            <p>Вторая дискуссия, модерируемая Джеймс Юг (Главный исполнительный директор CEDR)
                                сосредоточится на переходе к энергетике и государствах.
                                Темы будут даны Марк Аппель (Член ARbDB Chambers) Пол Силлс (Член в Арбитре), и Джо
                                Тирадо (Партнер в Garrigues) и будет включать в себя
                                дискуссии о том, что значит быть совместимым с Парижем и как государства перейдут на
                                проекты в области устойчивой энергетики.
                            </p>
                            <p>Наконец, в третьей дискуссии будет обсуждаться, как ESG вписывается в микс, модерируемый
                                Милосердие McBrayer (Руководитель отдела
                                профессиональной политики и арбитража в Ciarb). Участники дискуссии есть. Карл Маки
                                (Основатель президента в CEDR) Элиз Гроулкс
                                (Международный юрист по бизнесу и правам человека) Джиллиан Кирн (Советник по
                                устойчивому развитию и соблюдению в Совете по изменению климата),
                                и Мария Фогдестам Агиус (Партнер в Westerberg & Partners).
                            </p>
                        </div>
                    </div>

                </div>
                {/*<div className={s.Container2}>*/}

                {/*</div>*/}
            </div>
            <Events/>
            <MainMail/>
            {/*<Footer/>*/}
        </>
    )
}