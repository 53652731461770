import axios from "axios";
import {fetchCase} from "../http/http";
import {EventsType, SidesType, UserDataType} from "../types/types";
import {ThunkAction} from "redux-thunk";
import {AppRootStateType} from "./store";

const initialState = {
    userData: {
        // events: [] as EventsType[],
        // number: '',
        // date: '',
        // material_type: '',
        // subject: '',
        // judge: '',
        // review_date: '',
        // review_time: '',
        // sides: [] as SidesType[],
    } as UserDataType,
    isLoading: false,
    error:'',
    timerId: null as null | number,
}

type InitialStateType = typeof initialState

export const UserDataReducer = (state: InitialStateType = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case 'FETCH-USER-DATA':
            return {...state, userData: action.payLoad, error: ''}
        case 'SET-IS-LOADING':
            return {...state, isLoading: action.payLoad}
        case 'SET-ERROR':
            return {...state, error: action.payLoad}
        case 'SET-TIMER-ID':
            return {...state, timerId: action.payLoad}
        default:
            return state
    }
}

export const actionCreator = (userData: UserDataType) => {
    return {type: 'FETCH-USER-DATA', payLoad: userData} as const
}
export const setIsLoadingAC = (isLoading: boolean) => {
    return {type: 'SET-IS-LOADING', payLoad: isLoading} as const
}
export const setErrorAC = (error: string) => {
    return {type: 'SET-ERROR', payLoad: error} as const
}
export const setTimerIdAC = (timerId: null | number) => {
    return {type: 'SET-TIMER-ID', payLoad: timerId} as const
}

type AcType = ReturnType<typeof actionCreator>
type SetIsLoadingAcType = ReturnType<typeof setIsLoadingAC>
type SetERRORAcType = ReturnType<typeof setErrorAC>
type ActionsType = AcType | SetIsLoadingAcType | SetERRORAcType | ReturnType<typeof setTimerIdAC>;

export const FetchUserDataTC = (value: string | undefined): ThunkAction<Promise<void>, AppRootStateType, any, ActionsType> => (dispatch) => {
    dispatch(setIsLoadingAC(true))
    dispatch(setErrorAC(''))
    return fetchCase(value).then(res => {
        dispatch(actionCreator(res.data))
    }).catch(error => {
       dispatch(setErrorAC('Дело не найдено'))
    })
        .finally(() => {
                dispatch(setIsLoadingAC(false))
            }
        )
}

export const FetchUserDataWithDebounceTC = (value: string): ThunkAction<void, AppRootStateType, any, ActionsType> => (dispatch, getState) => {
    let timerId = getState().userData.timerId;

    if (timerId) {
        clearTimeout(timerId)
    }
    dispatch(setIsLoadingAC(true))
    const nextTimerId = setTimeout(() => {
        dispatch(setErrorAC(''))
        return fetchCase(value).then(res => {
            dispatch(actionCreator(res.data))
        }).catch(error => {
            dispatch(setErrorAC('Дело не найдено'))
        })
            .finally(() => {
                    dispatch(setIsLoadingAC(false))
                }
            )
    }, 400, null)
    dispatch(setTimerIdAC(nextTimerId))
}
