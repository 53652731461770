import {Navbar} from "./navbar/Navbar";
import {Main} from "./main/Main";
import {AboutScc} from "./aboutScc/Scc";
import {Services} from "./services/Services";
import {Production} from "./production/Production";
import {Counter} from "./counter/Counter";
import {Events} from "./mainEvents/Events";
import {ExpertsAdvice} from "./advice/ExpertsAdvice";
import {MainMail} from "./mainMailing/MainMail";
import {Footer} from "./footer/Footer";
import {Navbar2} from "../navbar2/Navbar2";
import {Hint} from "../hint/Hint";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Support} from "./support/Support";
import News2 from "../News2/News2";

type FilterType = 'gr' | 'ru' | 'en'

export const MainPage = () => {

    // const [filter, setFilter] = useState<FilterType>('gr');
    const {t, i18n} = useTranslation();
    return (
        <div>
            {/*<Hint/>*/}
            <Main/>
            <AboutScc/>
            <Production/>
            <Services/>

            <Counter/>
            {/*<Events/>*/}
            <News2/>
            <MainMail/>
            <ExpertsAdvice/>
            {/*<Support/>*/}
        </div>
    )
}