import React from "react";
import s from "./ExpertsAdvice.module.css";
import mailStyle from '../mailing/Mail.module.css';
import img1 from "./imgs/img1.png";
import img2 from "./imgs/img2.jpeg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {PrevArrow} from "./PrevArrow";
import {NextArrow} from "./NextArrow";
import {Content} from "./content/Content";
import {Aside} from "../../aside/Aside";
import {t} from "i18next";
import {useTranslation} from "react-i18next";



export const ExpertsAdvice = () => {
    const {t} = useTranslation()

    const settings = {
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 2500,
        slidesToShow: 1,
        infinite: true,
        slidesToScroll: 1,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
    };

    const content = [
        {   img:img1,
            h3:t("ExpertsAdvice.headeing1Slide"),
            p:  t("ExpertsAdvice.descr1Slide"),
            Link:"/Guide_to_arbitration"
        },
        {   img:img2,
            h3: t("ExpertsAdvice.headeing2Slide"),
            p: t("ExpertsAdvice.descr2Slide"),
            Link:"/Arbitration_rules"
        }
    ]


    return (
        <div className={s.Wrapper}>
            <div className={s.Container}>
                <Aside value={t("ExpertsAdvice.aside")}/>
                <div className={s.Column}>
                    <Slider {...settings} className={s.CustomSlider}>{
                        content.map((tl, index, arr) => {
                            return <Content position={`${index + 1}/${arr.length}`}
                                            p={tl.p}
                                            h3={tl.h3}
                                            img={tl.img}
                                            Link={tl.Link}
                            />
                        })
                    }</Slider>
                </div>
            </div>
        </div>
    )
}