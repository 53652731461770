import s from "./ShortCase.module.css";
import {Aside} from "../../aside/Aside";
import {BtnArrow} from "../../btnArrow/BtnArrow";
import React, {Dispatch, SetStateAction} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

type ShortCaseType = {
    value:number
    currency:'EUR' | 'USD'
    setPrice:Dispatch<SetStateAction<number | null>>
    countOfArbitrators:number
    price:number
    OverheadCosts:number
    AdministrativeFee:number
    TaxValue:number
}

export const ShortCase = (props:ShortCaseType) => {
    const {t, i18n} = useTranslation();
    return (
        // <div className={s.Wrapper}>
        //     <div className={s.Container}>
        //         <Aside value={'Калькулятор стоимости'}/>
        <div className={s.Content}>
            <div className={s.WrapperCounter}>
                <p>{t('Counter.ShortCase.estimatedCost')}</p>
                <h2>{props.price} {props.currency}</h2>
                <button onClick={() => props.setPrice(0)}>{t('Counter.ShortCase.Recalculate')}</button>
            </div>
            <div className={s.Column2}>
                <table className={s.TableShort}>
                    <tr className={s.TableRow}>
                        <th>{t('Counter.ShortCase.Rules')}</th>
                    </tr>
                    <tr className={s.TableRow}>
                        <td>{t('Counter.ShortCase.CollectionValue')}</td>
                        <td>{props.value} {props.currency}</td>
                    </tr>
                    <tr className={s.TableRow}>
                        <td>{t('Counter.CountOfArbitrators')}</td>
                        <td>{props.countOfArbitrators}</td>
                    </tr>
                    <tr className={s.TableRow2}>
                        <th>{t('Counter.ShortCase.FreeNDS')}</th>
                    </tr>
                    <tr className={i18n.language === "en" ? s.RusTableRow  : s.TableRow}>
                        <td >{t('Counter.ShortCase.Administrative')}</td>
                        <td>{props.AdministrativeFee} {props.currency} {t('Counter.ShortCase.NDS')}</td>
                    </tr>
                    <tr className={i18n.language === "en" ? s.RusTableRow  : s.TableRow}>
                        <td>{t('Counter.ShortCase.OverHeadCosts')}</td>
                        <td>{props.OverheadCosts} {props.currency} {t('Counter.ShortCase.NDS')}</td>
                    </tr>
                    <tr className={i18n.language === "en" ? s.RusTableRow  : s.TableRow}>
                        <td>{t('Counter.ShortCase.TaxValue')}</td>
                        <td>{props.TaxValue} {props.currency} {t('Counter.ShortCase.NDS')}</td>
                    </tr>
                    <tr className={i18n.language === "en" ? s.RusTableRow  : s.TableRow}>
                        <td className={s.Common}>{t('Counter.ShortCase.Common')}</td>
                        <td className={s.Common}>{props.price} {props.currency} {t('Counter.ShortCase.NDS')}</td>
                    </tr>
                </table>
                {/*<div className={s.WrapperVtn}>*/}
                    <Link to={'/Guide_to_arbitration'}className={s.WrapperVtn}>
                        <BtnArrow value={t('Counter.NextStep')}/>
                    </Link>
                {/*</div>*/}
            </div>
        </div>

        // </div>
        // </div>
    )
}