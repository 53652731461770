import React from "react";
import s from "./BtnArrow.module.css";
import {ReactComponent as Union} from "./Union.svg";

type BtnArrowType = {
    value:string
    onClick?:() => void
    disabled?: boolean
}

export const BtnArrow = (props:BtnArrowType) => {
    return(
      <>
          <button disabled={props.disabled} className={s.Btn} type={'submit'}  onClick={props.onClick}>
              {props.value}
          </button>
          {/*disabled={props.disabled}*/}
          <Union/>
      </>
    )
}
