import React from "react";
import s from "./Mail.module.css";
import {Aside} from "../../aside/Aside";
import {BtnArrow} from "../../btnArrow/BtnArrow";
import {Formik, Field, Form} from 'formik';
import {ReactComponent as Union} from "./Union.svg";
import {ReactComponent as Vector} from "./Vector.svg";
import {useTranslation} from "react-i18next";

const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));


export const MainMail = () => {
    // if(true){
    //     return <div>Loading...</div>
    // }
    const {t} = useTranslation()
    return (
        <div className={s.Wrapper} id={'Mail'}>
            <div className={s.Container}>
                <Aside value={t("MainMailing.Aside")}/>
                <div className={s.FormBlock}>
                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                            telephone: '',
                        }}
                        onSubmit={async (values, {setStatus}) => {
                            await sleep(500);
                            // alert(JSON.stringify(values, null, 2));
                            setStatus(values)
                        }}
                    >
                        {({isSubmitting, status}) => (
                            !status ?
                                <Form className={s.FF}>
                                    <div className={s.FormContainer}>
                                        <div className={s.Column1}>
                                            <label htmlFor="email"  className={s.Label}>{t("MainMailing.Email")}</label>
                                            <Field name="email" required type={'email'}
                                                   placeholder={t("MainMailing.Email")} className={s.Field}/>
                                        </div>
                                    </div>
                                    <div className={s.PolicyPrivacy}>
                                        <label className={s.PrivacyLAbel}>
                                            <Field type="checkbox" required name="checked" value="One"/>
                                            <p>{t("MainMailing.policyPrivacy")}
                                            </p>
                                        </label>
                                    </div>
                                    <div className={s.BtnWrapper}>
                                        <button type="submit" disabled={isSubmitting} className={s.Btn}>
                                            {t("MainMailing.Btn")}
                                        </button>
                                        <Union/>
                                    </div>
                                </Form>
                                : <div className={s.Success}>
                                    <Vector/>
                                    <p>{t("MainMailing.Success")}</p>
                                </div>)}
                    </Formik>
                </div>
            </div>
        </div>
    )
}