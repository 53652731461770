import s from "./Main.module.css";
import {ReactComponent as Logo} from "./imgs/LogoType.svg";
import {ReactComponent as Union} from "./imgs/Union.svg";
import React from "react";
import {MainSlider} from "./mainSlider/MainSlider";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ReactComponent as Telephone} from "./imgs/tel.svg";


export const Main = () => {
    const {t} = useTranslation()

    return (
        <div className={s.Wrapper} id={"Main"}>
            <div className={s.Container}>
                <div className={s.WrapperLogo}>
                    <div>
                        <Logo className={s.Logo}/>
                        <p>Cyprus Arbitrage<br/>
                            EC&CI
                        </p>
                    </div>
                    <div className={s.Contact}>
                        <span><Telephone className={s.Tel}/>+35777788242</span>
                        <p>{t("freeLine")}</p>
                    </div>
                </div>
                <div className={s.MainBlock}>
                    <MainSlider/>
                    <div className={s.Description}>
                        <h1>{t("MainHeading")}</h1>
                        <Link
                            to={"/Contact_us"}
                            className={s.BtnWrapper}
                        >
                            <button className={s.Btn}>
                                {t("ContactUs")}
                            </button>
                            <Union/>

                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}