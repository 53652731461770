import React from "react";
import s from './MoreDetailed.module.css';
import {t} from "i18next";

export const MoreDetailed = () => {
    return(
        <>
            <button className={s.Detail}>{t("SCCMore")}</button>
        </>
    )
}