import s from "./Navbar2.module.css";
import React, {useEffect, useState} from "react";
import {Menu2} from "./menu/Menu2";
import {useTranslation} from "react-i18next";


const Icon = () => {
    return (
        <div>
            <svg width="27" height="19" viewBox="0 0 27 19" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.375 18.25V16.0625H26.625V18.25H0.375ZM0.375 10.5938V8.40625H26.625V10.5938H0.375ZM0.375 2.9375V0.75H26.625V2.9375H0.375Z"
                    fill="white"/>
            </svg>
        </div>
    )
}
const Icon2 = () => {
    return (
        <div className={s.Icon2}>
            <svg width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.375 18.25V16.0625H19.3333V18.25H0.375ZM25.0938 16.3542L18.2031 9.46354L25.0573 2.60937L26.625 4.17708L21.3385 9.46354L26.6615 14.7865L25.0938 16.3542ZM0.375 10.5208V8.33333H14.9583V10.5208H0.375ZM0.375 2.9375V0.75H19.3333V2.9375H0.375Z"
                    fill="#A1A1AB"/>
            </svg>
        </div>
    )
}


type FilterType = 'gr' | 'ru' | 'en';

type Navbar2Type = {
    // filter:FilterType
    language: string
    changeLanguage: (language: any) => void
}

const ConditionalBlur = ({show, children, onClick}: any) => {
    if (!show)
        return <>{children}</>
    return <div onClick={onClick} className={s.Blur}>
        {children}
    </div>
}

export const Navbar2 = () => {

    const {t, i18n} = useTranslation()
    const changeLanguage = (language: FilterType) => {
        i18n.changeLanguage(language as string)

    }

    const [navbar, setNavbar] = useState(false)
    const [menu, setMenu] = useState(false)
    const [menuActive, setMenuActive] = useState<boolean>(false);
    useEffect(() => {
        const changeBackground = () => {
            if (window.scrollY >= 206) {
                setNavbar(true)
            } else {
                setNavbar(false)
            }
        }
        const changeColorMenu = () => {
            if (window.scrollY > 744) {
                setMenu(true)
            } else {
                setMenu(false)
            }
        }
        changeBackground()
        changeColorMenu()
        // adding the event when scroll change background
        window.addEventListener("scroll", changeBackground)
        window.addEventListener("scroll", changeColorMenu)
        return () => {
            window.removeEventListener("scroll", changeBackground)
            window.removeEventListener("scroll", changeColorMenu)
        }
    },[])



    useEffect(() => {
        const id1 = setTimeout(() => {
            setMenuActive(false)
        }, 0)
        const id = setTimeout(() => {
            setMenuActive(true)
        }, 10_000)

        return () => {clearTimeout(id)}
    }, [])
    const toggleMenu = () => setMenuActive(!menuActive);
    return (
        <>
            {/*{menuActive && <div onClick={() => setMenuActive(false)} className={s.Blur}/>}*/}
            <div
                className={`${s.Wrapper} ${menuActive ? s.ActiveMenu : ''} ${(!menuActive && navbar) ? s.HiddenMenu : ''}`}>
                {/*// className={menuActive ? s.ActiveMenu : s.Wrapper} onClick={toggleMenu}>*/}
                <div className={`${s.ContainerOfAll} ${menuActive ? s.ContainerOfAllActive : ''}`}
                     onClick={(e) => e.stopPropagation()}>

                    <div className={s.ContainerOfNav}>
                        <div className={(!menuActive && menu) ? s.BlackMenu : s.Menu} onClick={toggleMenu}>
                            {menuActive ? '' : <p>{t("Menu")}</p>}
                            {menuActive ? <Icon2/> : <Icon/>
                            }
                        </div>
                        <ul className={(!menuActive && navbar) ? s.NoneLanguages : s.Languages}>
                            <li
                                className={i18n.language === 'gr' ? s.FilteredGr : s.Item}
                                onClick={() => changeLanguage("gr")}>Gr
                            </li>
                            <li
                                className={i18n.language === "ru" ? s.Filtered : s.Item}
                                onClick={() => changeLanguage("ru")}>Ru
                            </li>
                            <li
                                className={i18n.language === "en" ? s.FilteredEn : s.Item}
                                onClick={() => changeLanguage("en")}>En
                            </li>
                        </ul>
                    </div>
                    {menuActive
                        ?
                        <Menu2 active={menuActive} setActiveMenu={setMenuActive} />
                        // <div className={`${s.Block} ${menuActive ? s.BlockActive : ''}`}></div>
                        : ''

                    }
                </div>
            </div>
        </>
    )
}