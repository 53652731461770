import React, {Suspense} from 'react';
import './App.css';
import {Footer} from "./components/mainPage/footer/Footer";
import {FullCase2} from "./components/casePage/fullCase2/FullCase";
import {EventPage} from "./components/eventPage/EventPage";
import {AllEvents, NavMenu} from "./components/allEvents/AllEvents";
import {MainPage} from "./components/mainPage/MainPage";
import {
    Route,
    Routes,
    Outlet, useLocation
} from 'react-router-dom';

import {Hint} from "./components/hint/Hint";
import {Navbar2} from "./components/navbar2/Navbar2";
import {NotFound} from "./components/404/NotFound";
import {lazy} from 'react';
import {Support} from "./components/mainPage/support/Support";
import {NavbarRight} from "./components/navBarRight/NavbarRight";


const Layout = () => {
    const location = useLocation()
    return (
        <div className="App">
            <Outlet/>
            <Navbar2/>
            {/*<Hint/>*/}
            <NavbarRight/>
            <Support/>
            <Footer/>
        </div>)

}

const Layout2 = () => {
    return (
        <>
            <NavMenu/>
            <Outlet/>
        </>
    )
}
const Courts = lazy(() => import("./components/courts/Courts"));

const Services1 = lazy(() => import("./components/ourServices/Service1/FirstPageWithText"));
const Services2 = lazy(() => import("./components/ourServices/Service2/Services2"));
const Services3 = lazy(() => import("./components/ourServices/Service3/Services3"));
const Services4 = lazy(() => import("./components/ourServices/Service4/Services4"));
const Services5 = lazy(() => import("./components/ourServices/Service5/Services5"));
const Services6 = lazy(() => import("./components/ourServices/Service6/Services6"));
const Services7 = lazy(() => import("./components/ourServices/Service7/Services7"));
const Services8 = lazy(() => import("./components/ourServices/Service8/Services8"));
const StepBystep = lazy(() => import("./components/AccordiobPages/secondAccordion/SecondAAccordionPage"));
const OurServices = lazy(() => import("./components/ourServices/OurServices"));
const ArbitrationRules = lazy(() => import("./components/AccordiobPages/firstAccordionPage/FirstAccordionPage"));
const ArbitrationAppointment = lazy(() => import("./components/aboutUs/arbitratorAppointment/arbitrationAppointment"));
const News = lazy(() => import("./components/News2/News2Wrapper"));


function App() {
    return (
        <Routes>
            <Route path={'/'} element={<Layout/>}>
                <Route index element={<MainPage/>}/>
                {/*<Route path={'Cyprus_Arbitrage'} element={<MainPage/>}/>*/}
                <Route path={'Contact_us'} element={
                    <Suspense fallback={<div>Loading...</div>}><Courts/>
                    </Suspense>}/>
                <Route path={'Guide_to_arbitration'} element={
                    <Suspense fallback={<div>Loading...</div>}><StepBystep/>
                    </Suspense>}/>
                <Route path={'Our_services'} element={
                    <Suspense fallback={<div>Loading...</div>}><OurServices/>
                    </Suspense>}/>
                <Route path={'EC&CI_Arbitration'} element={
                    <Suspense fallback={<div>Loading...</div>}><Services1/>
                    </Suspense>}/>
                <Route path={'EC&CI_Expedited_Arbitration'} element={
                    <Suspense fallback={<div>Loading...</div>}><Services2/>
                    </Suspense>}/>
                <Route path={'EC&CI_Express'} element={
                    <Suspense fallback={<div>Loading...</div>}><Services3/>
                    </Suspense>}/>
                <Route path={'EC&CI_Emergency_Arbitrator'} element={
                    <Suspense fallback={<div>Loading...</div>}><Services4/>
                    </Suspense>}/>
                <Route path={'EC&CI_Mediation'} element={
                    <Suspense fallback={<div>Loading...</div>}><Services5/>
                    </Suspense>}/>
                <Route path={'EC&CI_Investment_Disputes'} element={
                    <Suspense fallback={<div>Loading...</div>}><Services6/>
                    </Suspense>}/>
                <Route path={'SEC&CI_Ad_Hoc_Services'} element={
                    <Suspense fallback={<div>Loading...</div>}><Services7/>
                    </Suspense>}/>
                <Route path={'EC&CI_UNCITRAL'} element={
                    <Suspense fallback={<div>Loading...</div>}><Services8/>
                    </Suspense>}/>
                <Route path={'Arbitration_rules'} element={
                    <Suspense fallback={<div>Loading...</div>}><ArbitrationRules/>
                    </Suspense>}/>
                <Route path={'Arbitration_an_appointment'} element={
                    <Suspense fallback={<div>Loading...</div>}><ArbitrationAppointment/>
                    </Suspense>}/>
                <Route path={'News'} element={
                    <Suspense fallback={<div>Loading...</div>}><News/>
                    </Suspense>}/>
                {/*<Route path={'events'} element={<Layout2/>}>*/}
                {/*    <Route index element={<AllEvents/>}/>*/}
                {/*    <Route path={'all'} element={<AllEvents/>}/>*/}
                {/*    <Route path={'arbitrary'} element={<div>arbitrary</div>}/>*/}
                {/*    <Route path={'course'} element={<div>course</div>}/>*/}
                {/*    <Route path={'event'} element={<div>event</div>}/>*/}
                {/*</Route>*/}
                <Route path={'eventPage'} element={<EventPage/>}/>
                <Route path={'case/:id'} element={<FullCase2/>}/>

                {/*<AllEvents/>*/}
                {/*<EventPage/>*/}
                {/*<NewPage/>*/}
                {/*<News2/>*/}
                {/*<Courts/>*/}
                {/*<FullCase2/>*/}
                {/*<ShortCase/>*/}
                {/*<MainPage/>*/}
                <Route path='*' element={<NotFound/>}/>
            </Route>
        </Routes>
    );
}

export default App;
