import React from "react";
import s from "./Aside.module.css";

type AsideType = {
    value:string
}

export const Aside = (props:AsideType) => {
    return(
        <>
            <button className={s.Subscribe}>{props.value}</button>
        </>
    )
}