import React, {useState} from "react";
import s from "./NavbarRight.module.css";
import {useTranslation} from "react-i18next";
import {ReactComponent as Icon} from "./Vector.svg";
import {ReactComponent as Icon2} from "./Vector2.svg";
import {Link} from "react-router-dom";
import {ReactComponent as Logo} from "../mainPage/footer/LogoType.svg";
import {MenuRight} from "./MenuRight";

export const NavbarRight = () => {
    const {t} = useTranslation()
    const [isActive, setIsActive] = useState(false);
    const toggleMenu = () => setIsActive(!isActive)

    return (
        <>
            <div className={s.Wrapper}>
                <div className={s.Container}>
                    <div onClick={() => setIsActive(false)}>
                        <Link to={'/'} className={s.WrapperLogo}>
                            <Logo className={s.Logo}/>
                            <p>Cyprus Arbitrage<br/>
                                EC&CI
                            </p>
                        </Link>
                    </div>
                    <div className={`${s.Menu} ${isActive ? s.ActiveMenu : ''}`} onClick={toggleMenu}>
                        <p>{t("Menu")}</p>
                        {isActive ? <Icon2/> : <Icon/>}
                    </div>
                </div>
            </div>
            <MenuRight active={isActive} setActiveMenu={setIsActive}/>

        </>
    )
}
